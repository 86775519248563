import {createStore, combineReducers, applyMiddleware} from 'redux';

import appReducer from './reducers/app';
import questionReducer from "./reducers/question";
import topicReducer from "./reducers/topic";
import answerReducer from "./reducers/answer";

const myLog = store => next => action => {
    //console.log('Action Log :', action);
    next(action);
};

const store = createStore(combineReducers({
    app: appReducer,
    question: questionReducer,
    topic: topicReducer,
    answer: answerReducer
}), {}, applyMiddleware(myLog));

//store.subscribe(() => console.log('store: ', store.getState()));

export default store;