import React from 'react';
import PropTypes from 'prop-types';

class CRadio extends React.Component {
    render() {
        let {
            id,
            label,
            name,
            value,
            onChange
        } = this.props;
        return (
            <div className="custom-control custom-radio">
                <input
                    type="radio"
                    id={id}
                    className="custom-control-input"
                    name={name}
                    value={value}
                    onChange={e => onChange(e)}/>
                <label
                    className="custom-control-label"
                    htmlFor={id}>
                    {label}
                </label>
            </div>
        );
    }
}

CRadio.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func
};

export default CRadio;