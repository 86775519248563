const initState = {
    loadQuestion: false,
    loading: false,
    sendLoading: false,
    sendSuccess: false,
    currentPage: ''
};

export default (state = initState, action) => {

    switch (action.type) {

        case 'LOAD_QUESTION':
            state = {
                ...state,
                loadQuestion: action.payload
            };
            break;

        case 'SET_LOADING':
            state = {
                ...state,
                loading: action.payload
            };
            break;

        case 'SET_SEND_LOADING':
            state = {
                ...state,
                sendLoading: action.payload
            };
            break;

        case 'SEND_SUCCESS':
            state = {
                ...state,
                sendSuccess: action.payload
            };
            break;

        case 'STOP_LOAD_QUESTION':
            state = {
                ...state,
                loadQuestion: false,
                loading: false,
            };
            break;

        case 'SET_SEND_SUCCESS':
            state = {
                ...state,
                sendSuccess: action.payload,
            };
            break;

        case 'CLEAR_SEND_ANSWER':
            state = {
                ...state,
                sendLoading: false,
                sendSuccess: false,
            };
            break;

        case 'SET_CURRENT_PAGE':
            state = {
                ...state,
                currentPage: action.payload
            };
            break;

        default:
            break;
    }
    return state;
}