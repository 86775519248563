import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

class Loading extends React.Component {
    render() {
        return (
            <div className={`loading-container`}>
                <BarLoader width={300} height={10} color={'#ffffff'}/>
            </div>
        );
    }
}

export default Loading;