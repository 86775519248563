const initState = {

};

export default (state = initState, action) => {
    switch (action.type) {

        case 'SET_CURRENT_TOPIC':
            state = action.topic;
            break;

        case 'RESET_CURRENT_TOPIC':
            state = {};
            break;

        default:
            break;
    }
    return state;
}