import React from 'react';
import {connect} from 'react-redux';
import {TiArrowForward} from 'react-icons/ti';
import {setCurrentPage} from '../../redux/actions/app';
import {addAnswerToStore} from '../../redux/actions/answer';

import CRadio from "./custom-radio";

class Form extends React.Component {

    constructor(props) {
        super(props);

        this.initCouterNextPage = 3;
        this.initCouterToHome = 15;

        this.state = {
            answer: null,
            timerNextPage: null,
            timerGotoHome: null,
            counter: this.initCouterNextPage,
            counterToHome: this.initCouterToHome,
        };
    }

    componentDidMount() {
        this.props.setCurrentPage(
            this.props.match.params.topicId
        );

        this.startCounterToHome();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.topicId !== prevProps.match.params.topicId) {
            this.props.setCurrentPage(
                this.props.match.params.topicId
            );
        }
    }

    handleChangeInput(e) {

        this.setState({
            answer: e.target.value,
            counter: this.initCouterNextPage,
        });

        if(!this.state.timerNextPage) {
            let timerId = setInterval(() => this.counterNextPage(), 1000);
            this.setState({timerNextPage: timerId});
        }

        this.setState({counterToHome: this.initCouterToHome})
    }

    startCounterToHome() {
        if(!this.state.timerGotoHome) {
            let timerToHomeId = setInterval(() => this.counterToHome(), 1000);
            this.setState({timerGotoHome: timerToHomeId});
        }
    }

    handleClickNextPage() {
        this.resetCounterNextPage();
        this.goToNextPage();
    }

    counterNextPage() {
        let c = this.state.counter - 1;

        if(c < 0) {
            this.resetCounterNextPage();
            this.goToNextPage();
        }
        else {
            this.setState({counter: c});
        }
    }

    counterToHome() {
        let c = this.state.counterToHome - 1;

        if(c < 0) {
            this.setState({counterToHome: this.initCouterToHome});
            this.props.history.push('/');
        }
        else {
            this.setState({counterToHome: c});
        }
    }

    saveAnswer() {
        this.props.addAnswerToStore({
            subject_id: parseInt(this.props.currentPageId),
            ans: parseInt(this.state.answer)
        });
    }

    resetCounterNextPage() {
        clearInterval(this.state.timerNextPage);
        this.setState({
            timerNextPage: null,
            counter: this.initCouterNextPage
        });
    }

    goToNextPage() {
        let nextId = parseInt(this.props.currentPageId) + 1;

        this.saveAnswer();

        if(nextId > this.props.totalPage) {
            this.props.history.push(`/finished`);
        } else {
            this.props.history.push(`/topic/${nextId}`);
        }
    }

    render() {
        let choice = JSON.parse(this.props.choice);
        let subject_id = this.props.subject_id;

        return (
            <div className={'wp'}>
                <div className={`container-fluid`}>
                    {
                        choice.map((val, i) =>
                            <CRadio
                                key={`choice-${subject_id}-${i}`}
                                id={`choice-${subject_id}-${i}`}
                                label={val}
                                name={`choice_${subject_id}`}
                                value={i}
                                onChange={e => this.handleChangeInput(e)} />
                            )
                    }
                </div>
                <div className={`btn-wrapper text-center mt-2`}>
                    {
                        this.state.timerNextPage ?
                            <button
                                type={`button`}
                                className={`btn btn-lg btn-curve btn-primary`}
                                onClick={() => this.handleClickNextPage()}>
                                <TiArrowForward />
                                หัวข้อถัดไป
                                <span>({this.state.counter})</span>
                            </button> : null
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentPageId: state.app.currentPage,
        subject_id: state.topic.subject_id || 0,
        choice: state.topic.choice || "[]",
        totalPage: state.question.length,
    };
}

export default connect(mapStateToProps, {
    setCurrentPage,
    addAnswerToStore
})(Form);