import React from 'react';
import {connect} from 'react-redux';
import {
    fetchQuestion,
    fetchSaveAnswer
} from '../fn/fetch-data';
import {
    setLoading,
    setLoadQuestion,
    setStopLoadQuestion,
    setSendLoading,
    setSendSuccess
} from '../redux/actions/app';
import {
    setCurrentTopic
} from '../redux/actions/topic';
import {
    setStoreQuestion
} from '../redux/actions/question';

class AppManagement extends React.Component {

    fetchQuestion() {
        let {loadQuestion, loading} = this.props.app;

        if(!loading && loadQuestion) {
            this.props.setLoading(true);

            fetchQuestion()
                .then(res => this.props.setStoreQuestion(res.data))
                .finally(() => this.props.setStopLoadQuestion())
                .catch(err => console.error(err));
        }
    }

    getCurrentTopic() {
        let {currentPage, loading} = this.props.app;
        let topic = this.props.topic;
        let question = this.props.question;

        let currentPageId = parseInt(currentPage);

        if(!loading && !isNaN(currentPageId) && question.length > 0) {
            let _topic = question.find(res => res.subject_id === currentPageId);

            //console.log('_topic : ',_topic);
            if(_topic && topic !== _topic) {
                this.props.setCurrentTopic(_topic);
            }
        }
    }

    sendAnswer() {
        let answer = this.props.answer;
        let question = this.props.question;
        let {sendLoading, sendSuccess, currentPage} = this.props.app;

        if(currentPage === 'finished' && !sendLoading && !sendSuccess) {
            this.props.setSendLoading(true);
            //console.log('sending');
            if(answer.length === question.length) {
                fetchSaveAnswer(answer)
                    .then(() => {
                        this.props.setSendSuccess(true);
                    })
                    .catch(err => console.error('fetchSaveAnswer :', err));
            }
            else {
                this.props.setSendSuccess(true);
                console.log('ตอบคำถามไม่ครบ');
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.fetchQuestion();
        this.getCurrentTopic();
        this.sendAnswer();
    }

    render() {
        return this.props.children;
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps, {
    setLoading,
    setLoadQuestion,
    setStopLoadQuestion,
    setStoreQuestion,
    setCurrentTopic,
    setSendLoading,
    setSendSuccess
})(AppManagement);