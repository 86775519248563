import React from 'react';
import {connect} from 'react-redux';
import Dot from './dot';

class Footer extends React.Component {

    renderDots() {
        let dots = [];

        for(let i = 0; i < this.props.totalPage; i++) {
            dots.push(<Dot key={i} index={i}/>)
        }

        return dots;
    }

    render() {
        return (
            <div className={`layout-footer`}>
                <div className={`dot-wrapper`}>
                    {this.renderDots().map(dot => dot)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        totalPage: state.question.length
    };
}

export default connect(mapStateToProps)(Footer);