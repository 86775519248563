import axios from 'axios';
import * as moment from 'moment';

const server = "https://survey.yourteedin.com";

function fetchQuestion() {
    return axios.get(`${server}/api/question`);
}

function fetchSaveAnswer(answer) {
    return axios.post(`${server}/api/question/store`, {
        sender_id: moment().format('X').toString() + '-' + (Math.floor(Math.random() * 9999) + 1).toString(),
        answer: JSON.stringify(answer)
    });
}

export {
    fetchQuestion,
    fetchSaveAnswer
}