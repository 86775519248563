const initState = [];

export default (state = initState, action) => {

    switch (action.type) {

        case 'STORE_QUESTION':
            state = action.payload || [];
            break;

        case 'CLEAR_STORE_QUESTION':
            state = [];
            break;

        default:
            break;
    }

    return state;
}