function setStoreQuestion(payload) {
    return {
        type: 'STORE_QUESTION',
        payload
    }
}

function setClearQuestionStore() {
    return {
        type: 'CLEAR_STORE_QUESTION'
    }
}

export {
    setStoreQuestion,
    setClearQuestionStore
}