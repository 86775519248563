import React from 'react';

class Error404 extends React.Component {
    render() {
        return (
            <div>404 Error</div>
        );
    }
}

export default Error404;