import React from 'react';
import {connect} from 'react-redux';
import {TiHomeOutline} from 'react-icons/ti';
import BeatLoader from 'react-spinners/BeatLoader';
import {setCurrentPage} from '../../redux/actions/app';

class Finished extends React.Component {

    constructor(props) {
        super(props);
        this.initCouterToHome = 15;
        this.state = {
            timerGotoHome: null,
            counterToHome: this.initCouterToHome,
        }
    }

    componentDidMount() {
        this.props.setCurrentPage('finished');
        this.startCounterToHome();
    }

    handleClickBack() {
        this.setState({counterToHome: this.initCouterToHome});
        this.props.history.push('/');
    }

    startCounterToHome() {
        if(!this.state.timerGotoHome) {
            let timerToHomeId = setInterval(() => this.counterToHome(), 1000);
            this.setState({timerGotoHome: timerToHomeId});
        }
    }

    counterToHome() {
        let c = this.state.counterToHome - 1;

        if(c < 0) {
            this.setState({counterToHome: this.initCouterToHome});
            this.props.history.push('/');
        }
        else {
            this.setState({counterToHome: c});
        }
    }

    render() {
        return (
            <div className={`page-container page-finished`}>
                {
                    this.props.sendSuccess ?
                        (
                            <div className={`page-finished-wrapper`}>
                                <div className={`checker`}>
                                    <svg>
                                        <circle
                                            className="path circle"
                                            fill="none"
                                            stroke="#ffffff"
                                            strokeWidth="6"
                                            strokeMiterlimit="10"
                                            cx="65.1" cy="65.1" r="62.1"/>
                                        <polyline
                                            className="path check"
                                            fill="none"
                                            stroke="#ffffff"
                                            strokeWidth="6"
                                            strokeLinecap="round"
                                            strokeMiterlimit="10"
                                            points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                    </svg>
                                </div>
                                <div className={`text-wrapper`}>
                                    <p>บันทึกแบบสำรวจความพึงพอใจเรียบร้อย</p>
                                    <p>ขอบคุณค่ะ</p>
                                </div>
                                <div className={`text-center`}>
                                    <button
                                        type={`button`}
                                        className={`btn btn-lg btn-curve btn-primary`}
                                        onClick={() => this.handleClickBack()}>
                                        <TiHomeOutline /> กลับหน้าหลัก
                                    </button>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className={`page-finished-wrapper`}>
                                <p className="text-white" style={{fontSize: '1.5rem'}}>
                                    กำลังบันทึกแบบสำรวจความพึงพอใจ โปรดรอสักครู่...
                                </p>
                                <div className="d-flex justify-content-center">
                                    <BeatLoader color={'#ffffff'}/>
                                </div>
                            </div>
                        )
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        sendSuccess: state.app.sendSuccess
    };
}


export default connect(mapStateToProps, {
    setCurrentPage
})(Finished);