function addAnswerToStore(payload) {
    return {
        type: 'ADD_ANSWER',
        payload
    }
}

function clearAnswerStore() {
    return {
        type: 'CLEAR_ANSWER'
    }
}

export {
    addAnswerToStore,
    clearAnswerStore
}