import React from 'react';
import {connect} from 'react-redux';

class Header extends React.Component {
    render() {
        return (
            <div className={`layout-header`}>
                <span>{this.props.topic}</span>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        topic: state.topic.subject || '',
    }
}

export default connect(mapStateToProps)(Header);