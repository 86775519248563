import React from 'react';
import {connect} from 'react-redux';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {setLoadQuestion} from './redux/actions/app';

import LayoutForm from "./components/layout-form";
import Home from './views/home';
import Finished from "./views/finished";
import Error404 from "./views/errors/404";
import Loading from "./components/loading";

class App extends React.Component {

    componentDidMount() {
        this.props.setLoadQuestion(true);
    }

    render() {
        return (
            <div className={`root-container`}>
                <div className={`root-wrapper`}>
                    <HashRouter>
                        <Switch>
                            <Route path={`/`} component={Home} exact={true}/>
                            <Route path={`/finished`} component={Finished} exact={true}/>
                            <Route component={LayoutForm} exact={true}/>
                            <Route component={Error404} exact={true}/>
                        </Switch>
                    </HashRouter>
                    {this.props.loading ? <Loading /> : null}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.app.loading,
    }
}

export default connect(mapStateToProps, {setLoadQuestion})(App);