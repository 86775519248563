function setLoadQuestion(payload) {
    return {
        type: 'LOAD_QUESTION',
        payload
    };
}

function setStopLoadQuestion() {
    return {
        type: 'STOP_LOAD_QUESTION'
    };
}

function setLoading(payload) {
    return {
        type: 'SET_LOADING',
        payload
    };
}

function setSendLoading(payload) {
    return {
        type: 'SET_SEND_LOADING',
        payload
    };
}

function setSendSuccess(payload) {
    return {
        type: 'SET_SEND_SUCCESS',
        payload
    };
}

function setCurrentPage(payload) {
    return {
        type: 'SET_CURRENT_PAGE',
        payload
    };
}

function clearSendAnswer() {
    return {
        type: 'CLEAR_SEND_ANSWER'
    };
}

export {
    setLoadQuestion,
    setStopLoadQuestion,
    setLoading,
    setCurrentPage,
    setSendLoading,
    setSendSuccess,
    clearSendAnswer
}