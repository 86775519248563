import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {TiTick} from 'react-icons/ti';
import classNames from 'classnames';

class Dot extends React.Component {
    render() {
        let index = this.props.index + 1;
        let {currentPageId} = this.props;
        return (
            <div className={classNames('dot', {
                success: index < currentPageId,
                current: index === currentPageId
            })}>
                {index < currentPageId ? <TiTick /> : null}
            </div>
        );
    }
}

Dot.propTypes = {
    index: PropTypes.number.isRequired
};

function mapsStateToProps(state) {
    return {
        currentPageId: parseInt(state.app.currentPage)
    }
}

export default connect(mapsStateToProps)(Dot);