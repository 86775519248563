import React from 'react';
import {connect} from 'react-redux';

import {TiPen} from 'react-icons/ti';
import {setCurrentPage, clearSendAnswer} from '../../redux/actions/app'
import {clearAnswerStore} from '../../redux/actions/answer';

import booth from '../../assets/images/booth_name.png';
import logo from '../../assets/images/slri_logo.png';

class Home extends React.Component {

    componentDidMount() {
        this.props.setCurrentPage('home');
    }

    handleClick() {
        this.props.history.push(`/topic/1`);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentPage === 'home' && prevProps.currentPage !== 'home') {
            //console.log('reset');
            this.props.clearSendAnswer();
            this.props.clearAnswerStore();
        }
    }

    render() {
        return (
            <div className={`page-container page-home`}>
                <div className={`page-home-wrapper`}>
                    <div className={'booth-name'}>
                        <img src={booth} alt="" />
                    </div>
                    <div className={`logo-wrapper`}>
                        <img src={logo} alt=""/>
                    </div>
                    <div className={`text-wrapper`}>
                        <h1>แบบสำรวจความพึงพอใจผู้เข้าร่วมนิทรรศการ</h1>
                        <h2>สถาบันวิจัยแสงซินโครตรอน (องค์การมหาชน)</h2>
                        <h3>มหกรรมวิทยาศาสตร์และเทคโนโลยีแห่งชาติ ประจำปี 2562</h3>
                    </div>
                    <div className={`action-wrapper`}>
                        <button
                            type={`button`}
                            className={`btn btn-lg btn-curve btn-primary`}
                            onClick={() => this.handleClick()}>
                            <TiPen />....เริ่มทำแบบสำรวจกันเลยยย...
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentPage: state.app.currentPage
    }
}

export default connect(mapStateToProps, {
    setCurrentPage, clearAnswerStore, clearSendAnswer
})(Home);