function setCurrentTopic(topic) {
    return {
        type: 'SET_CURRENT_TOPIC',
        topic
    }
}

function setResetCurrentTopic() {
    return {
        type: 'RESET_CURRENT_TOPIC'
    }
}

export {
    setCurrentTopic,
    setResetCurrentTopic
}