const initState = [];

export default (state = initState, action) => {

    switch (action.type) {

        case "ADD_ANSWER":
            let nextState = state;
            nextState.push(action.payload);
            state = nextState;
            break;

        case "CLEAR_ANSWER":
            state = [];
            break;

        default:
            break;
    }

    return state;
}