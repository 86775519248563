import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';

import Header from "./header";
import Footer from "./footer";

import Form from "../../views/form";

class LayoutForm extends React.Component {

    render() {
        return (
            <div className={`layout-container`}>
                <Header/>
                <div className={`layout-content`}>
                    <Switch>
                        <Route path={`/topic/:topicId(\\d+)`} component={Form} exact />
                    </Switch>
                </div>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.app.loading,
        totalPage: state.question.length
    };
}

export default connect(mapStateToProps)(LayoutForm);